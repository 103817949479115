
.sidebarMenu
{
    height: 100vh;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 100;
    padding: 48px 0 0;
    box-shadow: inset -1px 0 0 rgba(0, 0, 0, .1);
}
h1 {
    text-align:center;
}
.buttonTable
{
    font-family: "Roboto", sans-serif;
    -webkit-font-smoothing: antialiased;
    font-weight: 400;
    line-height: 1.3;
    text-rendering: optimizeLegibility;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    
    
}