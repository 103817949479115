.activo{
    font-size: 1.5cap;
    text-decoration: underline;
 
}
.noActivo{
    font-size: 1.5cap;
   
   
}
.barNavText{
    font-size: x-small;
}