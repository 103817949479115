
body
{
    height: 100%;
    margin: 0;
     box-sizing: border-box;

}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.sidebarMenu
{
    height: 100vh;
    position: fixed;
    color: blue;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 100;
    padding: 48px 0 0;
    box-shadow: inset -1px 0 0 rgba(0, 0, 0, .1);
}
