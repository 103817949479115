.backgound_login{
    background-image: url("../../assets/Truck.webp");

}
.card_login{
    border-radius: 1rem;
}
.textLogin{
    letter-spacing: 1px;
}
